import { sprintf } from 'sprintf-js'

const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
const shortMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

const dateToYYYYMMDD = (date) => {
  return sprintf('%04d-%02d-%02d', date.getFullYear(), date.getMonth() + 1, date.getDate())
}

const yyyymmddToUsDateFormat = (date) => {
  if (date) {
    const parts = date.split(/-/)
    return `${parseInt(parts[1])}/${parseInt(parts[2])}/${parseInt(parts[0])}`
  }
  return null
}

const usDateFormatToShortMonthAndDay = (date) => {
  if (date) {
    const parts = date.split('/').map((num) => +num)
    const month = parts[0]
    const day = parts[1]

    return `${shortMonths[month - 1]} ${day}`
  }
  return null
}

const usDateFormatToYYYYMMDD = (date, centuryOffset = 2000, forceOffset = false) => {
  if (date) {
    const parts = date.split('/').map((num) => +num)
    const month = parts[0]
    const day = parts[1]
    let year = parts[2]

    if (year.length !== 4) {
      if (forceOffset) {
        year += centuryOffset
      } else if (year > 30) {
        // XXX
        // assume 1900s
        year += 1900
      } else {
        // assume 2000s
        year += centuryOffset
      }
    }

    return sprintf('%04d-%02d-%02d', year, month, day)
  }
  return null
}

const getNumberOfDays = (start, end) => {
  const date1 = new Date(start)
  const date2 = new Date(end)

  // One day in milliseconds
  const oneDay = 1000 * 60 * 60 * 24

  // Calculating the time difference between two dates
  const diffInTime = date2.getTime() - date1.getTime()

  // Calculating the no. of days between two dates
  const diffInDays = Math.round(diffInTime / oneDay)

  return diffInDays
}

const timestampToDateText = (date) => {
  console.log('date', date)
  if (!date) return null

  const dateObj = new Date(date)
  const month = months[dateObj.getMonth()]

  return `${month} ${dateObj.getDate()}, ${dateObj.getFullYear()}`
}

const timestampToShortDateText = (date) => {
  console.log('date', date)
  if (!date) return null

  const dateObj = new Date(date)
  const month = shortMonths[dateObj.getMonth()]

  return `${month} ${dateObj.getDate()}`
}

const timestampsToDateRange = (startDateTimestamp, endDateTimestamp) => {
  const starts = new Date(startDateTimestamp)
  const ends = new Date(endDateTimestamp)

  const startMonth = months[starts.getMonth()]
  const endMonth = months[ends.getMonth()]
  const startDate = starts.getDate()
  const endDate = ends.getDate()

  if (startMonth === endMonth) {
    if (startDate === endDate) {
      return `${startMonth} ${startDate}, ${starts.getFullYear()}`
    }
    return `${startMonth} ${startDate} - ${endDate}, ${ends.getFullYear()}`
  }

  return `${months[starts.getMonth()]} ${starts.getDate()} - ${months[ends.getMonth()]
    } ${ends.getDate()}, ${ends.getFullYear()}`
}
function convert24to12(time24) {
  const [hours, minutes] = time24.split(':');
  const period = +hours < 12 ? 'AM' : 'PM';
  const newHours = ((+hours % 12) || 12).toString();
  return `${newHours.padStart(2, '0')}:${minutes} ${period}`;
}

const getAge = (dateString) => {
  var today = new Date()
  var birthDate = new Date(dateString)
  var age = today.getFullYear() - birthDate.getFullYear()
  var m = today.getMonth() - birthDate.getMonth()
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--
  }
  return age
}

export {
  days,
  months,
  dateToYYYYMMDD,
  timestampToDateText,
  timestampToShortDateText,
  timestampsToDateRange,
  usDateFormatToShortMonthAndDay,
  usDateFormatToYYYYMMDD,
  yyyymmddToUsDateFormat,
  getNumberOfDays,
  convert24to12,
  getAge
}
